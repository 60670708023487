import * as React from "react"
import styled from "styled-components"
import Accordion from "../../../../accordion/Accordion"
import AccordionItem from "../../../../accordion/AccordionItem"
import AccordionHeader from "../../../../accordion/AccordionHeader"
import AccordionBody from "../../../../accordion/AccordionBody"
import Block from "../../../Block"
import Title from "../../../Title"

const Container = styled.div`
  margin: 30px auto 0;
  max-width: 800px;
`

export interface FaqConfigType {
  title: string
  content: string | React.ReactNode
}

interface Props {
  faqs: FaqConfigType[]
}

const LPFaqAccordionBlock: React.FC<Props> = ({ faqs }) => {
  return (
    <Block>
      <Container>
        <Title margin="0 0 30px 0">
          <b>Questions?</b> We’ve got you covered
        </Title>
        <Accordion>
          {faqs.map(({ title, content }) => (
            <AccordionItem key={title}>
              <AccordionHeader>{title}</AccordionHeader>
              <AccordionBody>{content}</AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </Block>
  )
}

export default LPFaqAccordionBlock
