import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import LPFaqAccordionBlock, { FaqConfigType } from "./LPFaqAccordionBlock"
import { skyBlue } from "../../../../../constants/colors"

const Container = styled.div``

const EnquiryFormLink = styled.u<{ color?: string }>`
  color: ${({ color }) => color || `${skyBlue}`};
  text-decoration: underline;
  cursor: pointer;
`

const getFaqs = (openEnquiryForm: () => void): FaqConfigType[] => [
  {
    title: "Is there a minimum order quantity?",
    content: `We are able to meet orders from 1 - 25,000. While there is no minimum order quantity
                to buy from us, there is a limit of 150 gift boxes for us to create a bespoke gift
                for you. For orders over 500 units, please provide us as much notice as possible.`,
  },
  {
    title: "How do you measure the positive impact from our purchase?",
    content: `We work with a range of social enterprise suppliers, each with a unique impact, from
                employing those furthest from the workplace to upcycling material that would
                normally go to waste. For each purchase we can quantify the social value created
                e.g. hours of employment created or kilograms of product saved. We try to be as
                transparent as possible when it comes to impact measurement and also be upfront
                about any assumptions included in the impact report.`,
  },
  {
    title: "Can I put my logo on the box?",
    content: `Yes, we offer many different options to provide co-branding opportunities from your
                box design, to gift notes and even place your logo on some of the products e.g.
                water bottles.`,
  },
  {
    title: "Can I include a gift note?",
    content: `Absolutely. You’ll just need to let us know that you’d like to include a gift note
                when we discuss your order. Note, there is a character limit of 240 characters
                per gift note.`,
  },
  {
    title: "Can I include some of my own products in the gift boxes?",
    content: (
      <>
        Yes. Please discuss your requirements with us. Either drop us a note via our{" "}
        <EnquiryFormLink onClick={openEnquiryForm}>enquiry form</EnquiryFormLink> or email us at
        info@socialsupermarket.org.
      </>
    ),
  },
  {
    title: "Do you ship internationally?",
    content: `Yes, we work with a number of logistics providers to deliver internationally. We can
                also support with the payment of duties and taxes so the recipient doesn't have to.`,
  },
  {
    title: "How long does delivery take and can I ship to multiple addresses?",
    content: `Delivery on our hamper range can be organised as a next day delivery. We can also
                arrange shipping to multiple addresses.`,
  },
  {
    title: "How do you ensure your packaging is eco-friendly?",
    content: `We work with our suppliers to eliminate any unnecessary plastic packaging. Our boxes
                are designed to be reused and are made from either wicker (a sustainable material)
                or cardboard for our magnetic boxes. All the fill and cushioning is biodegradable or
                recycled paper.`,
  },
]

interface Props {
  openEnquiryForm: () => void
}

const LPHamperFaqAccordionBlock: FC<Props> = ({ openEnquiryForm }) => {
  const faqs = getFaqs(openEnquiryForm)
  return <LPFaqAccordionBlock faqs={faqs} />
}

export default LPHamperFaqAccordionBlock
