import * as React from "react"
import CorporateHampersGiftBoxesLP from "../social-supermarket/components/landing-page/new/pages/CorporateHampersGiftBoxesLP"
import SEO from "../components/Seo"
import Layout from "../components/Layout"

const ChristmasHampersAndGiftBoxes = (): JSX.Element => {
  return (
    <Layout fullWidth>
      <SEO
        title="Sustainable Corporate Hampers & Gift Boxes"
        description="Sustainable & Ethical Corporate Hampers & Gift Boxes from the UK's best social enterprises."
      />
      <CorporateHampersGiftBoxesLP />
    </Layout>
  )
}

export default ChristmasHampersAndGiftBoxes
